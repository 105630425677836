<template>
  <div class="main">
    <div v-if="employer" class="columns is-centered is-multiline is-mobile">
      <div class="column is-full columns is-centered is-multiline is-mobile p-4">
        <div class="logo-gradient">
          <div class="logo-container">
            <img class="firm-logo" :src="`https://cdn.statically.io/img/${employer.logo.replace(/(https:\/\/)|-/g, '')}`">
          </div>
        </div>
        <p class="column is-full title has-text-white is-size-1 has-text-centered">{{employer.name}}</p>
        <b-taglist v-if="employer.accredited || employer.premium || employer.underbar">
          <b-tag type="is-green" v-if="employer.accredited">Accredited</b-tag>
          <b-tag type="is-gold" v-if="employer.premium">Diamond Sponsor</b-tag>
          <b-tag v-if="employer.underbar">Underbars Accepted</b-tag>
        </b-taglist>

        <div class="buttons field is-grouped is-grouped-centered column is-full">
          <b-button v-if="employer.appform" type="is-primary" tag="a" :href="employer.appform" target="_blank">Submit your CV</b-button>
          <b-button v-if="employer.website" tag="a" :href="employer.website" target="_blank" type="is-primary">Visit Website</b-button>
          <b-button v-if="employer.orientation" tag="a" :href="employer.orientation" target="_blank" type="is-primary">See Firm Talk</b-button>
        </div>

        <div class="column is-full columns is-centered mt-4" v-if="employer.avp || employer.brochure">
          <!-- AVP -->
          <div class="column" v-if="employer.avp" v-bind:class="{'is-half-tablet': employer.brochure, 'is-8-tablet': !employer.brochure}">
            <div class="media-container">
              <svg class="circle" width="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
                <path id="circlePath" fill="none" stroke-width="4" stroke="#DA2565" d="
                    M 15, 75
                    a 60,60 0 1,1 120,0
                    a 60,60 0 1,1 -120,0
                " />
                <text id="text" font-family="League Spartan" font-size="24" font-weight="bold" fill="white">
                    <textPath id="textPath" href="#circlePath">Firm's AVP</textPath>
                </text>
              </svg>
              <div class="orientation-box has-background-gold">
                <figure class="image is-16by9 has-background-grey">
                  <iframe :src="employer.avp" class="has-ratio"></iframe>
                </figure>
              </div>
            </div>
          </div>

          <!-- Brochure -->
          <div class="column" v-if="employer.brochure" v-bind:class="{'is-half-tablet': employer.avp, 'is-8-tablet': !employer.avp}">
            <div class="media-container">
              <svg class="circle" width="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
                <path id="circlePath" fill="none" stroke-width="4" stroke="#DA2565" d="
                    M 15, 75
                    a 60,60 0 1,1 120,0
                    a 60,60 0 1,1 -120,0
                " />
                <text id="text" font-family="League Spartan" font-size="24" font-weight="bold" fill="white">
                    <textPath id="textPath" href="#circlePath">Firm's Brochure</textPath>
                </text>
              </svg>
              <a class="pdf-link has-text-white" :href="employer.brochure" target="_blank">Download PDF</a>
              <div class="orientation-box has-background-light-blue">
                <figure class="image is-4by3 has-background-grey">
                  <div id="pdf-view" class="has-ratio"></div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-full columns is-variable is-gapless-mobile is-centered">
        <div v-if="employer.images" class="column is-half-tablet is-full-mobile">
          <b-carousel :autoplay="false" :arrow-hover="false" icon-size="is-medium" :has-drag="false" indicator-background>
            <b-carousel-item v-for="(item, i) in employer.images" :key="i">
              <a class="image" :href="item" target="_blank">
                <img :src="`https://cdn.statically.io/img/${item.replace(/(https:\/\/)|-/g, '')}`">
              </a>
            </b-carousel-item>
          </b-carousel>
          <p class="is-size-7 has-text-centered has-text-white is-italic">Click the image to enlarge</p>
        </div>

        <div class="column"
             v-bind:class="{'is-half-tablet': employer.images, 'is-two-thirds-tablet': !employer.images}"
             v-if="employer.writeup || employer.internship_details || employer.apply || employer.contact_details"
        >
          <div class="p-2 text-box is-full-mobile has-background-off-white">
            <b-tabs multiline v-model="activeTab">
              <b-tab-item label="About" v-if="employer.writeup">
                <div class="content">
                  <vue-markdown>{{employer.writeup}}</vue-markdown>
                </div>
              </b-tab-item>
              <b-tab-item :label="employer.underbar === true ? 'Internship & Underbar Details' : 'Internship Details'" v-if="employer.internship_details">
                <div class="content">
                  <vue-markdown>{{employer.internship_details}}</vue-markdown>
                </div>
              </b-tab-item>
              <b-tab-item label="Application Process & Requirements" v-if="employer.apply">
                <div class="content">
                  <vue-markdown>{{employer.apply}}</vue-markdown>
                </div>
              </b-tab-item>
              <b-tab-item label="Contact Details" v-if="employer.contact_details">
                <div class="content">
                  <vue-markdown>{{employer.contact_details}}</vue-markdown>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <div class="columns is-centered p-4">
        <div class="column is-half">
          <Loader></Loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {supabase} from "../supabaseClient";
import VueMarkdown from '@adapttive/vue-markdown'
import Loader from "../components/Loader";

export default {
  name: "EmployerView",
  components: {Loader, VueMarkdown},
  title: "Employer Details | Sibol: Ateneo Law School Career Fair 2024",
  data() { return {
    employer: null,
    id: this.$route.params.id,
    activeTab: 0,
    pdfApiReady: false,
    adobeDcView: null
  }},
  methods: {
    async loadEmployerData() {
      const {data, error} = await supabase
        .from('employers')
        .select()
        .eq('id',this.id)
      this.employer = data[0]
      if (error) {console.error(error)}
    }
  },
  watch: {
    pdfApiReady(val) {
      const vm = this
      if (val === true && vm.employer.brochure) {
        vm.adobeDcView = new AdobeDC.View({
          clientId: process.env.VUE_APP_ADOBE_CLIENT_ID,
          divId: "pdf-view"
        })
        vm.adobeDcView.previewFile({
          content:{location: {url: vm.employer.brochure}},
          metaData:{fileName: `${vm.employer.name}.pdf`}
        }, {embedMode: "SIZED_CONTAINER"})
      }
    }
  },
  created() {
    if(window.AdobeDC) this.pdfApiReady = true;
  },
  async mounted() {
    const vm = this
    await vm.loadEmployerData()
    document.title = `${this.employer.name} | ALS Career Fair 2024`
    let adobePdfEmbedScript = document.createElement('script')
    adobePdfEmbedScript.setAttribute('src', 'https://acrobatservices.adobe.com/view-sdk/viewer.js')
    document.head.appendChild(adobePdfEmbedScript)
    document.addEventListener("adobe_dc_view_sdk.ready", () => { vm.pdfApiReady = true; });
  }
}
</script>

<style scoped>
.main {
  padding-top: 6.9%;
  padding-bottom: 6.9%;
  background: #081444;
}

.logo-gradient {
  position: relative;
  width: 340px;
  height: 340px;
  border-radius: 50%;
  background: rgb(242,188,30);
  background: radial-gradient(circle, rgba(242,188,30,1) 0%, rgba(242,188,30,1) 60%, rgba(218,37,101,1) 70%);
  box-shadow: 5px 5px 10px 0 #081444 inset, -5px -5px 10px 0 #081444 inset;
}

.logo-container {
  position: absolute;
  width: 290px;
  height: 290px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin: -145px 0px 0px -145px;
  background: #ffffff;
}

.firm-logo {
  position:absolute;
  top:50%; left:50%;
  transform: translate(-50%, -50%);
  margin:0;
  max-width: 220px;
  max-height: 200px;
}

.media-container {
  position: relative;
}

.circle {
  position: absolute;
  left: 0;
  margin-top: -40px;
}

.pdf-link {
  position: absolute;
  right: 8px;
  margin-top: -36px;
}

.orientation-box {
  margin-left: 40px;
  padding: 18px;
  border-radius: 8px;
  margin-bottom: 36px;
}

.text-box {
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .text-box {
    border-radius: 0;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -8%;
  }
}
</style>